<template>
  <div>
    <v-btn
      :loading="loading"
      :disabled="disabled"
      @click="$emit('click')"
      :color="color"
      class="text-capitalize"
    >
      {{ labelComputed }}
      <v-icon v-if="icon">
        {{ icon }}
      </v-icon>
    </v-btn>
    <slot name="cancel">
      <span @click="$emit('cancel')">
        <cancel-button class="text-capitalize"> </cancel-button>
      </span>
    </slot>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  components: {
    CancelButton: () => import('../../components/Core/CancelButton.vue')
  },
  props: {
    update: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'primary'
    },
    disableSubmit: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState(['permissions']),
    labelComputed() {
      if (this.label) {
        return this.label;
      }
      if (this.update) {
        return 'Update';
      } else {
        return 'Save';
      }
    },
    disabled() {
      if (this.$store.getters.isAdmin) return false;
      return !this.permissions.update || this.disableSubmit;
    },
    icon() {
      if (this.update) {
        return 'mdi-content-save';
      } else {
        return 'mdi-content-save';
      }
    }
  }
};
</script>

<style>
</style>
